import React, { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import "./login.css";
import LoginData from "./LoginData.js"
import MensajeAlerta from "../../components/AlertMsg/MensajeAlerta.jsx";
import { useHistory } from 'react-router-dom';

const Login = () => {
    const alertRef = useRef(null);
    const history = useHistory();
    const [state, setState] = useState({
        showPassword: false,
        email: "",
        password: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const toggleShowPassword = (e) => {
        e.preventDefault()
        setState(prevState => ({
            ...prevState,
            showPassword: !prevState.showPassword
        }));
    };

    const vallidaciones = () => {
        const errores = {
            camposVacios: "Please fill out all fields"
        }

        const mostrarAlerta = (texto) => {
            if (alertRef.current) {
                alertRef.current.showAlert(texto, false)
            }
            return false
        }

        if (!state.email || !state.password) {
            return mostrarAlerta(errores.camposVacios)
        }

        return true
    }

    const validarRol = (userData) => {
        if (!userData) return
        if (userData.rol === 'admin')
            history.push('/admin');
        else if (userData.rol === "u")
            history.push('/Dashboard/home');
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        if (!vallidaciones()) return

        const loginData = new LoginData();
        const result = await loginData.iniciarSesion(state, validarRol)

        if (result) {
            if (alertRef.current) alertRef.current.showAlert(result, false)
        }
    }

    return (
        <div className="login-seccion">
            {state.isLoading ? (
                <div className="spinner"></div>
            ) : (
                <>
                    <MensajeAlerta ref={alertRef} />
                    <div className="login-main-box">
                        <div className="login-box">
                            <img alt="logo" />
                            <div className="lg">
                                <hr />
                                <span>sing in</span>
                                <hr />
                            </div>
                            <form>
                                <label htmlFor="username">Username</label>
                                <input type="text" name="email" placeholder="Enter Username" value={state.email} onChange={handleChange} />
                                <label htmlFor="password">Password</label>
                                <div className="password">
                                    <input type={state.showPassword ? 'text' : 'password'} name="password" placeholder="Enter Password" value={state.password} onChange={handleChange} />
                                    <button className="show" onClick={(e) => toggleShowPassword(e)}>
                                        <i className={state.showPassword ? "bi bi-eye-slash" : "bi bi-eye"}></i>
                                    </button>
                                </div>
                                <input type="submit" value="Login" onClick={(e) => handleLogin(e)} />
                                <Link className="fyp" to={`/recovery_password`}><p >Forgot your password?</p></Link>
                            </form>
                        </div>
                        <img className="imagen" alt="img1" />
                    </div>
                </>
            )}
        </div>
    )
}

export default Login;