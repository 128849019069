import logoUSDT from "../../../Assets/Images/Logos/usdt.png";
import "./PagarFacturas.css";
import TextInput from "../../../components/TextInput/TextField";
import { useState, useRef, useEffect } from "react";
import Common from "../../../components/js/Common";
import PeticionModel from "../../../model/PeticionModel"
import img1 from "../../../Assets/Images/qr/qrms.png"
import { useUser } from "../../../context/UserContext";
import MensajeAlerta from "../../../components/AlertMsg/MensajeAlerta";
import { Historial as HistorialTable } from "../../../components/tablas/Historial";
import { ItemPagoFactura } from "../../../components/tablas/items/ItemPagoFactura";
import InputNip from "../../../components/NipModal/InputNip";

const PagarFacturas = () => {
    const [state, setState] = useState({
        code: '',
        seleccionado: 1,
        visibleModal: false,
        starterPack: ''
    })

    const alertRef = useRef(null)
    const nipRef = useRef(null)
    const { userData, isLoading } = useUser()
    const [propietario, setPropietario] = useState([])
    const [peticion, setPeticion] = useState([])
    const [historial, setHistorial] = useState(null)

    const changeState = (name, value) => {
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const encontrarFactura = async () => {
        if (!validacion()) return

        const peticionRepo = new PeticionModel()
        const userRepo = new Common()

        try {
            const peticion = await peticionRepo.getFactura(state.code)
            if (peticion) {
                const starterPack = userRepo.determinarPaquete(Number(peticion.monto))
                changeState('starterPack', starterPack)
                setPeticion(peticion)
                const propietarioData = await userRepo.fetchUserDataByName(peticion.email)
                setPropietario(propietarioData)
                changeState('visibleModal', true)
            } else {
                alertRef.current.showAlert("invoice cannot be found", false)
            }
        } catch (error) {
            alertRef.current.showAlert("invoice cannot be found", false)
            console.log(error)
        }
    }

    const pagarFactura = async () => {
        const wallet = state.seleccionado == 1 ? userData.walletDiv : (state.seleccionado == 2 ? userData.walletCom : userData.creditoCorpo)

        if (!validacion(wallet)) return

        if (state.seleccionado == 1) {
            userData.walletDiv -= peticion.monto * 0.5
        } else if (state.seleccionado == 2) {
            userData.walletCom -= peticion.monto * 0.5
        } else {
            userData.creditoCorpo -= peticion.monto * 0.5
        }

        const userRepo = new Common()
        const peticionRepo = new PeticionModel("Bill payment by transfer", peticion.monto * .5, "")
        try {
            peticionRepo.save()
            peticionRepo.borrar(peticion.firebaseKey)
            const fechaHora = userRepo.obtenerFechaHoraActual()
            const historiModel = {
                userName: userData.userName,
                emisor: propietario.userName,
                monto: peticion.monto / 2,
                montoMax: peticion.monto,
                date: fechaHora.date,
                hora: fechaHora.hora,
                paquete: state.starterPack,
                wallet: state.seleccionado,
                concepto: 'Payment with invoice'
            }
            await userRepo.saveHistory(historiModel)
            userRepo.editAnyUser(userData)
            changeState('isLoading', 3)
            changeState('code', '')
        } catch (error) {
            alertRef.current.showAlert("Error to paid", false)
            console.log(error)
        }

    }

    const validacion = (wallet) => {
        const errores = {
            codeNotFound: "Code not found",
            insufficientBalance: "You do not have enough balance to withdraw this amount",
            minBalanceAfterWithdraw: "After withdrawing you need to have at least 25 USDT remaining",
            permisoBloqueado: "You do not have permissions to perform this action",
        }
        const mostrarError = (mensaje) => {
            alertRef.current.showAlert(mensaje, false)
            return false;
        };
        if (state.code == "" || !state.code) {
            return mostrarError(errores.codeNotFound)
        }
        if (wallet <= 0) {
            return mostrarError(errores.insufficientBalance)
        }
        if (wallet < peticion.monto * .5) {
            return mostrarError(errores.insufficientBalance)
        }
        if (state.seleccionado == 1 && Number(wallet - peticion.monto * .5) < 25) {
            return mostrarError(errores.minBalanceAfterWithdraw)
        }
        if (state.seleccionado == 1 && !userData.permisos.permitirPagarDiv) {
            return mostrarError(errores.permisoBloqueado)
        }
        return true
    }

    const changeSeleccion = (opc) => {
        changeState('seleccionado', Number(opc))
    }

    const handleCopy = () => {
        const inputElement = document.getElementById("wallet");
        inputElement.select();
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        alertRef.current.showAlert("Address copied successfully", true)
        console.log(alertRef.current.showAlert("Address copied successfully", true))
    };

    const fetchHisotrial = async () => {
        const userRepo = new Common()
        const historialData = await userRepo.fetchHistoryByConcept('Payment with invoice',userData.userName)
        setHistorial(historialData.reverse())
    }

    return (
        <section className="PagarFacturas contenido">
            <MensajeAlerta ref={alertRef} />
            <InputNip ref={nipRef} proceso={pagarFactura} />
            {state.visibleModal && (
                confirmarTransaccionModal()
            )}
            <section className="titulos titulo-pf">
                <i class="bi bi-receipt"></i>
                <span>Facturas</span>
            </section>
            {isLoading ? (
                <div className="spinner"></div>
            ) : (
                <>
                    <section className="contenido-pf">
                        <section className="transaccion-area-pf">
                            <section className="inputArea">
                                <p className="titulo">Select your wallet</p>
                                <div className="wallets">
                                    {WalletOpcion("Dividend wallet", userData.walletDiv, 1)}
                                    {WalletOpcion("Comission wallet", userData.walletCom, 2)}
                                    {userData.creditoCorpo > 0 && (
                                        WalletOpcion("Corporate credit", userData.creditoCorpo, 3)
                                    )}
                                </div>
                                <TextInput ti={"Billing code"} value={state.code} name={'code'} changeState={changeState} pl={'Code here'} />
                                <button className="boton4" onClick={(e) => encontrarFactura()}><span>Find invoice</span></button>
                            </section>
                            <section className="notas-pf">
                                <p className="titulo">How to pay the remaining 50%?</p>
                                <h4 className="textoM">Scan the QR code to pay or copy the wallet address into your preferred application.</h4>
                                <div className="copyAddrs">
                                    <h4 >Wallet address:</h4>
                                    <div className="wallet">
                                        <input type="text" id="wallet" readOnly value="TMuMJUSBamBf1d2vhbd4g1p13pUf6N7TtM" />
                                        <button onClick={handleCopy} ><i class="bi bi-copy"></i></button>
                                    </div>
                                    <div className="or">
                                        <div className="line" />
                                        <h4>Or</h4>
                                        <div className="line" />
                                    </div>
                                    <div className="qr">
                                        <h4>Qr code:</h4>
                                        <img src={img1} alt="QR code" />
                                    </div>
                                </div>
                            </section>
                        </section>

                        <HistorialTable historial={historial} fetchHistory={fetchHisotrial}>
                            {(item) => (
                                <tr>
                                    <ItemPagoFactura item={item} />
                                </tr>
                            )}
                        </HistorialTable>

                    </section>
                </>
            )
            }
        </section >


    )

    function WalletOpcion(titulo, wallet, opc) {
        return (
            <div onClick={() => changeSeleccion(opc)} className={state.seleccionado == opc ? "seleccionado walletOpcion" : "walletOpcion"}>
                <h3>{titulo}</h3>
                <div>
                    <img src={logoUSDT} alt="usdt_logo" />
                    <span>{(wallet || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')}</span>
                </div>
            </div>
        )
    }

    function confirmarTransaccionModal() {

        const openNipModal = () => {
            nipRef?.current?.show()
            changeState('visibleModal', false)
        }

        return (
            <section className="confirmarTransaccionModal">
                <div className="overlay"></div>
                <section className="confirmarTransaccion-contenido">
                    <header>
                        <h1>Transaction details</h1>
                        <button onClick={() => changeState('visibleModal', false)}><i class="bi bi-x"></i></button>
                    </header>
                    <div className=" ">
                        <div className="titulo"><i class="bi bi-person"></i><span>Recipient user</span></div>
                        <h5>Username: <span>{propietario.userName}</span></h5>
                        <h5>Name: <span>{propietario.firstName}</span></h5>
                        <h5>Last name: <span>{propietario.lastName}</span></h5>
                        <h5>email: <span>{propietario.email}</span></h5>
                    </div>
                    <div className="datosDeTranferencia">
                        <div className="titulo"><i class="bi bi-coin"></i><span>Invoice details</span></div>
                        <h5>Selected wallet: <span>{state.seleccionado == 1 ? ("Dividend") : state.seleccionado == 2 ? ("Commision") : ('Corporate credit')}</span></h5>
                        <h5>Starter pack: <span>{state.starterPack}</span></h5>
                        <h5>Maximun to pay: <span>{(peticion.monto).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.') || "0.00"} USDT</span></h5>
                        <h5>Total amount: <span>{(peticion.monto / 2).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.') || "0.00"} USDT</span></h5>
                        <button onClick={openNipModal}><i class="bi bi-send"></i>Pay</button>
                    </div>
                </section>
            </section>
        )
    }
}



export default PagarFacturas