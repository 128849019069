import NavBtn from "./NavBtn"
import { useEffect, useState } from "react";
import { getAuth, signOut } from "firebase/auth"
import appFirebase from "../../firebase-config";
import { useUser } from "../../context/UserContext";
import { useHistory } from 'react-router-dom';

const auth = getAuth(appFirebase)
const opciones = [
    {
        ruta: "/Dashboard/home",
        icono: "bi bi-house",
        titulo: "Home"
    },
    {
        ruta: "/Dashboard/packs",
        icono: "bi bi-boxes",
        titulo: "Starter packs"
    },
    {
        ruta: "/Dashboard/My-net",
        icono: "bi bi-people",
        titulo: "My net"
    },
    {
        ruta: "/Dashboard/My-net-genealogy",
        icono: "bi bi-diagram-3",
        titulo: "Genealogy"
    },
    {
        ruta: "/Dashboard/benefits",
        icono: "bi bi-graph-up-arrow",
        titulo: "benefits"
    },
    {
        ruta: "/Dashboard/withdrawals",
        icono: "bi bi-person-vcard",
        titulo: "withdrawals"
    },
    {
        ruta: "/Dashboard/internal-transfers",
        icono: "bi bi-cash-coin",
        titulo: "Internal transfers"
    },
    {
        ruta: "/Dashboard/bill-payment",
        icono: "bi bi-receipt",
        titulo: "Bill Payment"
    },
    {
        ruta: "/Dashboard/E-comerce",
        icono: "bi bi-bag",
        titulo: "E-comerce"
    },
    {
        ruta: "/Dashboard/cooming-soon",
        icono: "bi bi-archive",
        titulo: "Tools"
    },
    {
        ruta: "/Dashboard/Support",
        icono: "bi bi-exclamation-octagon",
        titulo: "Support"
    },
    {
        ruta: "/Dashboard/Profile",
        icono: "bi bi-person-gear",
        titulo: "Edit profile"
    },
    {
        ruta: null,
        icono:"bi bi-box-arrow-right",
        titulo:"Logout"
    },


]
const SiderNav = ({toggleMenu}) => {
    const { logout, userData } = useUser()
    const history = useHistory();

    const handleLogout = (e) => {
        e.preventDefault()
        signOut(auth)
            .then(() => {
                logout()
                console.log("Usuario desconectado exitosamente");
                history.push('/');
            })
            .catch((error) => {
                console.error("Error al desconectar al usuario:", error);
            });
    };

    const cambiarRuta = (e, ruta) => {
        e.preventDefault()
        if (!ruta) handleLogout(e)
        else history.push(ruta);

        toggleMenu()
    }

    return (
        <nav class="navegacion">
            <ul>
                <h4>Main menu</h4>
                <hr />
                {opciones.map((item, index) => (
                    <li>
                        <div className="opcionBoton2" onClick={(e) => cambiarRuta(e, item.ruta)}>
                            <i className={item.icono}></i>
                            <span>{item.titulo}</span>
                        </div>
                    </li>
                ))}
                {userData?.deuda > 0 && (
                    <li>
                        <div className="opcionBoton2" onClick={(e) => cambiarRuta(e, "/Dashboard/corporate-credit")}>
                            <i className={"bi bi-credit-card-2-front"}></i>
                            <span>{"Corporate credit"}</span>
                        </div>
                    </li>
                )}
            </ul>
        </nav>
    )
}

export default SiderNav