import logoUSDT from "../../../Assets/Images/Logos/usdt.png";
import "./Retiros.css";
import TextInput from "../../../components/TextInput/TextField";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import AlertMsj from "../../../components/AlertMsg/MensajeAlerta";
import NipModal from '../../../components/NipModal/InputNip'
import { getDatabase, ref, get, query, orderByChild, equalTo } from "firebase/database";
import appFirebase from "../../../firebase-config";
import Common from "../../../components/js/Common";
import PeticionModel from "../../../model/PeticionModel"
import { useUser } from "../../../context/UserContext";

const Retiros = () => {
  const { userData } = useUser()
  const alertRef = useRef()
  const nipRef = useRef()

  const [state, setState] = useState({
    cantidad: 0,
    isLoading: true,
    isLoadingRP: true,
    seleccionado: 1,
    retiroActivo: false,
    botonDisabled:false
  })
  const [historial, setHistorial] = useState([]);
  const [retiroPendiente, setRetiroPendiente] = useState([])

  const changeState = (name, value) => {
    setState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const fetchHistorial = async () => {
    try {
      const db = getDatabase(appFirebase);
      const dbRef = ref(db, "history");

      // Consulta que filtra directamente por userName
      const snapshot = await get(
        query(dbRef, orderByChild("userName"), equalTo(userData.userName))
      );

      if (snapshot.exists()) {
        // Filtrar los registros que contengan "retiro" en el concepto
        const filteredHistorys = Object.values(snapshot.val())
          .filter(({ concepto }) => concepto?.toLowerCase().includes("withdrawl"))
          .reverse();
        setHistorial(filteredHistorys);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      changeState("isLoading", false);
    }
  };


  const fetchRetiroPendiente = () => {
    return new Promise(async (resolve) => {

      try {
        const db = getDatabase(appFirebase);
        const dbRef = ref(db, "peticiones");
        const queryDb = query(dbRef, orderByChild("userName"), equalTo(userData.userName));
        const snapshot = await get(queryDb);

        if (snapshot.exists()) {
          const data = snapshot.val();
          const retiroEntry = Object.values(data).find(item => item.concepto == "Retiro");

          if (retiroEntry) {
            return resolve(retiroEntry);
          } else {
            return resolve([]);
          }
        } else {
          return resolve([]);
        }
      } catch (error) {
        return resolve([]);
      }
    });
  };

  useEffect(() => {
    if (!userData) return;
    fetchRetiroPendiente().then(retiro => {
      setRetiroPendiente(retiro)
      changeState('isLoadingRP', false)
    })
    fetchHistorial()
  }, [userData])

  useEffect(() => {
    fetchPermisos()
  }, [userData]);

  const fetchPermisos = () => {
    if (!userData) return null

    if (!userData.permisos) {
      userData.permisos = {}
    }

    if (!("retiroDiv" in userData.permisos))
      userData.permisos.retiroDiv = true
    if (!("retiroCom" in userData.permisos))
      userData.permisos.retiroCom = true
  }

  const validacion = () => {
    const retiroMinimo = state.seleccionado == 1 ? 50 : 10
    const wallet = state.seleccionado == 1 ? userData.walletDiv : userData.walletCom
    const errores = {
      invalidValue: "The value you entered is not valid",
      noWallet: "You have not yet registered your USDT wallet",
      completeProfile: "Complete your profile to be able to withdraw",
      insufficientBalance: "You do not have enough balance to withdraw this amount",
      minWithdrawal: `The minimum withdrawal amount is ${retiroMinimo} USDT`,
      minBalanceAfterWithdraw: "After withdrawing you need to have at least 25 USDT remaining",
      retiroActivo: "you already have a pending withdrawal",
      permisoBloqueado: "You do not have permissions to perform this action"
    };

    const mostrarError = (mensaje) => {
      alertRef.current.showAlert(mensaje, false)
      return false;
    };

    if (state.seleccionado == 1 && !userData.permisos.retiroDiv) {
      return mostrarError(errores.permisoBloqueado)
    }

    if (state.seleccionado == 2 && !userData.permisos.retiroCom) {
      return mostrarError(errores.permisoBloqueado)
    }

    if (retiroPendiente && Object.keys(retiroPendiente).length > 0) {
      return mostrarError(errores.retiroActivo)
    }
    if (isNaN(parseFloat(state.cantidad)) || hasMoreThanTwoDecimals(state.cantidad)) {
      return mostrarError(errores.invalidValue);
    }

    if (!userData.usdtAddress) {
      return mostrarError(errores.noWallet);
    }

    if (!userData.phoneNumber || !userData.firstName || !userData.lastName) {
      return mostrarError(errores.completeProfile);
    }

    if (state.cantidad > wallet) {
      return mostrarError(errores.insufficientBalance);
    }

    if (state.cantidad < retiroMinimo) {
      return mostrarError(errores.minWithdrawal);
    }

    if (state.seleccionado == 1 && userData.walletDiv - state.cantidad < 25) {
      return mostrarError(errores.minBalanceAfterWithdraw);
    }

    // Si todas las validaciones pasan
    return true;
  }

  function hasMoreThanTwoDecimals(num) {
    const str = num.toString();
    const decimalIndex = str.indexOf(".");
    return decimalIndex !== -1 && str.slice(decimalIndex + 1).length > 2;
  };

  const changeSeleccion = (opc) => {
    changeState('seleccionado', opc)
  }

  const openCloseNipModal = () => {
    if (!validacion()) return

    nipRef.current.show()
  };

  const solicitarRetiro = async () => {
    changeState('botonDisabled',true)
    const userRepo = new Common()
    if (state.seleccionado == 1) {
      userData.walletDiv = userData.walletDiv - state.cantidad
    } else {
      userData.walletCom = userData.walletCom - state.cantidad
    }
    userData.retiros = Number(userData.retiros) + Number(state.cantidad);

    try {
      changeState('isLoadingRP', true);
      const peticionModel = new PeticionModel("Retiro", state.cantidad, state.seleccionado)
      await peticionModel.saveRetiro()
      const retiro = await fetchRetiroPendiente();
      await userRepo.editAnyUser(userData)

      //setRetiroPendiente(retiro);
      //alertRef.current.showAlert("Request submitted successfully", true);
    } catch (error) {
      alertRef.current.showAlert("Request not submitted", false)
    }
  }

  return (
    <section className="contenido Retiros">
      <AlertMsj ref={alertRef} />
      <NipModal ref={nipRef} proceso={solicitarRetiro} />
      <section className="titulos titulo-re">
        <i className="bi bi-person-gear"></i>
        <span>Retiros</span>
      </section>

      <section className="contenido">
        <section className="retirar">
          <p className="titulo">Select your wallet</p>
          <div className="wallets">
            {WalletOpcion("Divident wallet", userData.walletDiv, 1)}
            {WalletOpcion("Comission wallet", userData.walletCom, 2)}
          </div>
          <TextInput ti={"Wallet address"} value={userData.usdtAddress} pl={0} block={false} changeState={() => { }} />
          <TextInput ti={"Amount to withdraw(USDT)"} value={state.cantidad} changeState={changeState} name={'cantidad'} pl={0} />

          <button disabled={state.botonDisabled} className="boton4" onClick={openCloseNipModal}><span>Request withdrawal</span></button>
        </section>

        <section className="notas">
          <p className="textoM2">Important notes:</p>
          <p className="textoM"><li>You need to have your USDT(TRC20) wallet address registered. If you don't have it yet,<Link to="Profile" className="link"> click here</Link></li></p>
          <p className="textoM"><li>Withdrawal requests: minimum amount of 50 USDT required for the <span>dividend wallet</span> and minimum amount of 10 USDT required for <span>commission wallet</span>.</li></p>
          <p className="textoM"><li>All requests will be approved only <span>Monday through Friday</span> starting at 12:00 a.m. (Miami time).</li></p>
          <p className="textoM"><li>Withdrawal <span>cost of 3% </span>for the total amount requested (Administrative expenses).</li></p>
          <p className="textoM"><li>When a withdrawal is requested the dividend wallet <span>must have at least 25 USDT</span>.</li></p>
        </section>
      </section>

      <section className="historial">
        <div className="titulo">
          <h2><i class="bi bi-clock-history"></i> Pending withdrawals</h2>
        </div>

        <table>
          <tbody>
            {state.isLoadingRP ? (
              <div className="spinner"></div>
            ) : (
              retiroPendiente && Object.keys(retiroPendiente).length !== 0 ? (
                <tr>
                  <td >{retiroPendiente.fecha}</td>
                  <td >{retiroPendiente.hora}</td>
                  <td><span>{retiroPendiente.monto} USDT</span></td>
                  <td className="e"><i class="bi bi-hourglass-split"></i> Pending</td>
                  <td >{retiroPendiente.wallet == 1 ? "Dividend wallet" : "Comission wallet"}</td>
                </tr>
              ) : (
                <tr>
                  <p className="aviso">You have no pending withdrawals</p>
                </tr>
              )
            )}
          </tbody>
        </table>
      </section>

      <section className="historial">
        <div className="titulo">
          <h2><i class="bi bi-clock-history"></i> History</h2>
        </div>

        <table>
          <tbody>
            {state.isLoading ? (
              <div className="spinner"></div>
            ) : (
              historial.length > 0 ? (
                historial.map((item) => (
                  <tr>
                    <td >{item.date}</td>
                    <td >{item.hora}</td>
                    <td><span>{item.cantidad} USDT</span></td>
                    <td className={item.state == 1 ? "r" : "acept"}>{item.state == 1 ? <span><i class="bi bi-x-circle"></i> Denied</span> : <span><i class="bi bi-check2-circle"></i>  Approved</span>}</td>
                    <td >{item.emisor}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <p className="aviso">You have not yet made a withdrawal</p>
                </tr>
              )
            )}
          </tbody>
        </table>
      </section>
    </section >
  )

  function WalletOpcion(titulo, wallet, opc) {
    return (
      <div onClick={() => changeSeleccion(opc)} className={state.seleccionado == opc ? "seleccionado walletOpcion" : "walletOpcion"}>
        <h3>{titulo}</h3>
        <div>
          <img src={logoUSDT} alt="usdt_logo" />
          <span>{(wallet || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')}</span>
        </div>
      </div>
    )
  }

}

export default Retiros