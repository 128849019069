import "./QrComponent.css"
import React, { useState, useRef } from 'react';
import { useUser } from '../../context/UserContext'
import qr from "../../Assets/Images/qr/qrms.png"
import img1 from "../../Assets/flaticons/qrfi.png"
import img2 from "../../Assets/flaticons/copyfi.png"
import img3 from "../../Assets/flaticons/advertecniafi.png"
import img4 from ".././../Assets/Images/Baners_jpg/pagometodo.png"
import logoUSDT from "../../Assets/Images/Logos/usdt.png"
import PeticioModel from "../../model/PeticionModel"
import AlertMsj from "../AlertMsg/MensajeAlerta"
import Common from "../js/Common";

const QrComponent = ({ visible, openClose, op }) => {
    const [state, setState] = useState({
        opcion: 0,
        opcionPago: '',
        seleccionado: '',
        code: ''
    })
    const opcionesPago = ["---", "USDT Transfer", "Pay by invoice/USDT"]
    const { userData } = useUser()
    const nota3 = {
        intruccion1: "1- select an amount and generate your payment code",
        instruccion2: "2- Copy the code so that someone else can pay for the package",
        advertencia1: "The maximum amount that can be paid through this method corresponds to 50%",
        advertencia2: "The remaining amount must be paid via USDT transfer (TRC20)"
    }
    const alertRef = useRef()

    const changeState = (name, value) => {
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    if (!visible) return

    const handleChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (isNaN(value)) {
            changeState('opcion', 0)
        } else {
            changeState('opcion', value)
        }
    };

    const handleCopy = () => {
        const inputElement = document.getElementById("wallet");
        inputElement.select();
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        alertRef.current.showAlert("Code copied successfully", true)
    };
    const handleCopyCode = () => {
        const inputElement = document.getElementById("codeF");
        inputElement.select();
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        alertRef.current.showAlert("Code copied successfully", true)
    };

    const generarOpciones = (op) => {
        switch (op) {
            case 1:
                return (
                    <>
                        <option value={0}>select an option</option>;
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                        <option value={300}>300</option>
                        <option value={400}>400</option>
                    </>
                );
            case 2:
                return (
                    <>
                        <option value={0}>select an option</option>;
                        <option value={500}>500</option>
                        <option value={600}>600</option>
                        <option value={700}>700</option>
                        <option value={800}>800</option>
                        <option value={900}>900</option>
                        <option value={1000}>1000</option>
                        <option value={1100}>1100</option>
                        <option value={1200}>1200</option>
                        <option value={1300}>1300</option>
                        <option value={1400}>1400</option>
                        <option value={1500}>1500</option>
                        <option value={1600}>1600</option>
                        <option value={1700}>1700</option>
                        <option value={1800}>1800</option>
                        <option value={1900}>1900</option>
                        <option value={2000}>2000</option>
                        <option value={2100}>2100</option>
                        <option value={2200}>2200</option>
                        <option value={2300}>2300</option>
                        <option value={2400}>2400</option>
                    </>
                );
            case 3:
                return (
                    <>
                        <option value={0}>select an option</option>;
                        <option value={2500}>2500</option>
                        <option value={2600}>2600</option>
                        <option value={2700}>2700</option>
                        <option value={2800}>2800</option>
                        <option value={2900}>2900</option>
                        <option value={3000}>3000</option>
                        <option value={3100}>3100</option>
                        <option value={3200}>3200</option>
                        <option value={3300}>3300</option>
                        <option value={3400}>3400</option>
                        <option value={3500}>3500</option>
                        <option value={3600}>3600</option>
                        <option value={3700}>3700</option>
                        <option value={3800}>3800</option>
                        <option value={3900}>3900</option>
                        <option value={4000}>4000</option>
                        <option value={4100}>4100</option>
                        <option value={4200}>4200</option>
                        <option value={4300}>4300</option>
                        <option value={4400}>4400</option>
                        <option value={4500}>4500</option>
                        <option value={4600}>4600</option>
                        <option value={4700}>4700</option>
                        <option value={4800}>4800</option>
                        <option value={4900}>4900</option>
                    </>
                );
            case 4:
                return (
                    <>
                        <option value={0}>select an option</option>;
                        <option value={5000}>5000</option>
                        <option value={5100}>5100</option>
                        <option value={5200}>5200</option>
                        <option value={5300}>5300</option>
                        <option value={5400}>5400</option>
                        <option value={5500}>5500</option>
                        <option value={5600}>5600</option>
                        <option value={5700}>5700</option>
                        <option value={5800}>5800</option>
                        <option value={5900}>5900</option>
                        <option value={6000}>6000</option>
                        <option value={6100}>6100</option>
                        <option value={6200}>6200</option>
                        <option value={6300}>6300</option>
                        <option value={6400}>6400</option>
                        <option value={6500}>6500</option>
                        <option value={6600}>6600</option>
                        <option value={6700}>6700</option>
                        <option value={6800}>6800</option>
                        <option value={6900}>6900</option>
                        <option value={7000}>7000</option>
                        <option value={7100}>7100</option>
                        <option value={7200}>7200</option>
                        <option value={7300}>7300</option>
                        <option value={7400}>7400</option>
                        <option value={7500}>7500</option>
                        <option value={7600}>7600</option>
                        <option value={7700}>7700</option>
                        <option value={7800}>7800</option>
                        <option value={7900}>7900</option>
                        <option value={8000}>8000</option>
                        <option value={8100}>8100</option>
                        <option value={8200}>8200</option>
                        <option value={8300}>8300</option>
                        <option value={8400}>8400</option>
                        <option value={8500}>8500</option>
                        <option value={8600}>8600</option>
                        <option value={8700}>8700</option>
                        <option value={8800}>8800</option>
                        <option value={8900}>8900</option>
                        <option value={9000}>9000</option>
                        <option value={9100}>9100</option>
                        <option value={9200}>9200</option>
                        <option value={9300}>9300</option>
                        <option value={9400}>9400</option>
                        <option value={9500}>9500</option>
                        <option value={9600}>9600</option>
                        <option value={9700}>9700</option>
                        <option value={9800}>9800</option>
                        <option value={9900}>9900</option>
                    </>
                );
            default:
                return <option value={0}>Seleccione una opción</option>;
        }
    };

    const setRequestHandle = (opcion) => {
        const peticionesData = new PeticioModel("Paquete de inicio", opcion)
        peticionesData.save()
        openClose()
    }
    const onOpenClose = () => {
        changeState('opcionesPago', null)
        openClose()
    }

    const changeSeleccion = (opc) => {
        changeState('seleccionado', opc)
    }

    const generarCodigo = () => {
        if (state.opcion == "Seleccione una opción") {
            alertRef.current.showAlert("Invalid option", false)
            return
        }

        const code=generarCodigoFactura()

        const factura = {
            concepto: "payment by invoice",
            monto: state.opcion,
            email: userData.email,
            code:code
        }

        const peticionesData = new PeticioModel()
        peticionesData.saveFactura(factura).then(() => {
            changeState('code', code)
        })
    }

    const generarCodigoFactura = () => {
        const timestamp = Date.now();
        const random = Math.floor(Math.random() * 10000000);
        return `FAC-${timestamp}-${random}`;
    }


    return (
        <section className='qrPago'>
            <AlertMsj ref={alertRef} />
            <div className="overlay"></div>
            <div className="modal-contentQr">

                {!state.opcionPago && (
                    <div className="selector">
                        <div className="titulo">
                            <img src={img4} alt="ic_pago" />
                            <p>Select a payment method</p>
                        </div>
                        <select value={state.opcionPago} onChange={(e) => changeState('opcionPago', e.target.value)}>
                            {opcionesPago.map((opcion, index) => (
                                <option key={index} value={opcion}>
                                    {opcion.charAt(0).toUpperCase() + opcion.slice(1)}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                {state.opcionPago === opcionesPago[1] && (
                    <>
                        <div className="payment">
                            <p className="textoM3">Complete the payment</p>
                            <img src={qr} alt="qr" />
                            <div className="copyAddrs">
                                <p >Wallet address to pay</p>
                                <div className="wallet">
                                    <input type="text" id="wallet" readOnly value="TMuMJUSBamBf1d2vhbd4g1p13pUf6N7TtM" />
                                    <button onClick={handleCopy} ><i class="bi bi-copy"></i></button>
                                </div>
                            </div>
                            <div className="monto">
                                <p>Amount</p>
                                <div>
                                    {op === 5 ? (
                                        <input type="text" id="wallet" value={state.opcion} onChange={handleChange} />
                                    ) : (
                                        <select className="select-box" value={state.opcion} onChange={handleChange} >
                                            {generarOpciones(op)}
                                        </select>
                                    )}
                                </div>
                            </div>
                            <button className="finish" onClick={() => setRequestHandle(state.opcion)}>Finish payment</button>
                            <button className="close" onClick={onOpenClose}><span>Close</span></button>
                        </div>
                        <div className="notasqr">
                            <div><p className="textoM3">How to make a deposit?</p></div>
                            <div className="nota">
                                <img src={img1} className="imgSec2-qr" alt="qric" />
                                <p>Scan the Qr code with your payment app</p>
                            </div>
                            <div className="or">
                                <p><i class="bi bi-dash"></i> or <i class="bi bi-dash"></i></p>
                            </div>
                            <div className="nota">
                                <img src={img2} className="imgSec2-qr" alt="qric" />
                                <p>Copy the USDT address and
                                    amount to pay, then paste them
                                    into your payment app
                                </p>
                            </div>
                            <div className="nota">
                                <img src={img3} className="imgSec2-qr" alt="qric" />
                                <p>Transfer only Tether USD TRC20
                                    token (USDT). Transferring
                                    other currency will result in the
                                    toss of funds
                                </p>
                            </div>
                            <div className="nota">
                                <img src={img3} className="imgSec2-qr" alt="qric" />
                                <p>
                                    Make sure to correctly send the transaction, verifying the exact amount and that the Wallet is correct.
                                    Any sending error will result in the loss of funds.
                                </p>
                            </div>
                        </div>
                    </>
                )}

                {state.opcionPago === opcionesPago[2] && (
                    <>
                        <div className="payment">
                            <p className="textoM3">Complete the payment</p>
                            <div className="monto">
                                <p className="textoM2">1-. Select a quantity</p>
                                <div>
                                    {op === 5 ? (
                                        <input type="text" id="code" value={state.opcion} onChange={handleChange} />
                                    ) : (
                                        <select className="select-box" value={state.opcion} onChange={handleChange} >
                                            {generarOpciones(op)}
                                        </select>
                                    )}
                                </div>
                            </div>
                            <button className="finish" onClick={generarCodigo}>Generate code</button>
                            <div className="copyAddrs">
                                <p className="textoM2">2-. Copy your code</p>
                                <div className="wallet">
                                    <input type="text" id="codeF" value={state.code} onChange={(e) => changeState('code', e.target.value)} readOnly />
                                    <button onClick={handleCopyCode} ><i class="bi bi-copy"></i></button>
                                </div>
                            </div>
                            <button className="close" onClick={onOpenClose}><span>Close</span></button>
                        </div>
                        <div className="notasqr">
                            <div><p className="textoM3">How to make a payment by invoice?</p></div>
                            <div className="nota">
                                <img src={img1} className="imgSec2-qr" alt="qric" />
                                <p>{nota3.intruccion1}</p>
                            </div>
                            <div className="nota">
                                <img src={img2} className="imgSec2-qr" alt="qric" />
                                <p>{nota3.instruccion2} </p>
                            </div>
                            <div className="nota">
                                <img src={img3} className="imgSec2-qr" alt="qric" />
                                <p>{nota3.advertencia1} </p>
                            </div>
                            <div className="nota">
                                <img src={img3} className="imgSec2-qr" alt="qric" />
                                <p>{nota3.advertencia2} </p>
                            </div>
                        </div>
                    </>
                )}

            </div>
        </section >
    )
}

export default QrComponent