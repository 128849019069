import React, { useRef } from "react"
import "./Register.css"
import { useState } from 'react';
import MensajeAlerta from "../../components/AlertMsg/MensajeAlerta"
import { useParams } from 'react-router-dom';
import emailjs, { send } from '@emailjs/browser';
import UserModel from '../../model/UserModel'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"
import { getDatabase, ref, set, push,child } from "firebase/database";
import appFirebase from "../../firebase-config";
const auth = getAuth(appFirebase)

const Register = () => {
    const { r } = useParams();
    const alertRef = useRef()
    const [state, setState] = useState({
        email: '',
        password: '',
        passwordConf: '',
        userName: '',
        referredBy: r || '',
        showPass: false,
        showPass2: false,
    })

    const changeState = (name, value) => {
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const validacion = () => {
        const errores = {
            passNoMatch: 'Passwords do not match.',
            passLess: 'Password should contain at least 6 characters.',
            emptyField: 'There are empty fields'
        }

        const showError = (msj) => {
            alertRef?.current?.showAlert(msj, false)
            return false
        }

        if (!state.email || !state.password || !state.passwordConf || !state.userName || !state.referredBy) {
            return showError(errores.emptyField)
        }

        if (state.password.length < 6) {
            return showError(errores.passLess)
        }
        if (state.password !== state.passwordConf) {
            return showError(errores.passNoMatch)
        }

        return true

    }

    const formatearFecha = () => {
        const fechaActual = new Date();

        const dia = fechaActual.getDate();
        const mes = fechaActual.getMonth() + 1;
        const anio = fechaActual.getFullYear();

        const fechaFormateada = `${dia}-${mes}-${anio}`;
        return fechaFormateada
    }

    const saveData = async () => {
        const userModel = new UserModel()
        userModel.setDefaultValues();
        const db = getDatabase(appFirebase);
        const newKey = push(child(ref(db), 'users')).key;

        userModel.firebaseKey = newKey;
        userModel.admissionDate = formatearFecha();
        userModel.email = state.email;
        userModel.password = state.password;
        userModel.referredBy = state.referredBy;
        userModel.userName = state.userName;
        userModel.password = state.password;

        await set(ref(db, `users/${newKey}`), userModel);
        window.location.href = '/Dashboard/home';
    }

    const handleChange = (e) => {
        const newValue = e.target.value;
        const sanitizedValue = newValue.replace(/[^\w\s]/gi, '').replace(/\s/g, "");
        changeState('userName', sanitizedValue)
    };

    const registrar = async (e) => {
        e.preventDefault();
        if (!validacion()) return

        try {
            await createUserWithEmailAndPassword(auth, state.email, state.password)
            await saveData();
            sendEmail()
        } catch (error) {
            switch (error.code) {
                case 'auth/email-already-in-use':
                    alertRef?.current?.showAlert('The email address is already in use. Please choose another.', false)
                    break;
                case 'auth/invalid-email':
                    alertRef?.current?.showAlert('The email address provided is invalid.', false)
                    break;
                case 'auth/too-many-requests':
                    alertRef?.current?.showAlert('Too many unsuccessful login attempts. Please try again later.', false);
                    break;
                default:
                    alertRef?.current?.showAlert('An error occurred during registration. Please try again later.', false);
            }
        }
    }


    const sendEmail = () => {
        emailjs.send("service_033kgeg", "template_ct13fi2", {
            password: state.password,
            userName: state.userName,
            destinatario: state.email,
        }, {
            publicKey: '0wCoAjcnZT2N0PVfE',
        })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    }

    const handleTogglePassword = (e, opc) => {
        e.preventDefault();
        if (opc == 1) {
            changeState('showPass', !state.showPass)
        } else {
            changeState('showPass2', !state.showPass2)
        }
    };

    const handleChangeMayusToMinus = (e) => {
        const value = e.target.value;
        const textoConvertido = value.toLowerCase();
        changeState('email', textoConvertido);
    }


    return (
        <div className="register-seccion">
            <MensajeAlerta ref={alertRef} />
            <div className="form-register">
                <img alt="logo" />
                <h2>Sing up</h2>
                <h4>Enter your username, email and password to register</h4>
                <span>Username:</span>
                <input type="text" placeholder="Enter your Username" value={state.userName} onChange={handleChange} />
                <span>Email:</span>
                <input type="email" placeholder="Enter your E-mail" value={state.email} onChange={handleChangeMayusToMinus} />
                <span>Password</span>
                <div className="password">
                    <input type={state.showPass ? 'text' : 'password'} placeholder="Enter Password" value={state.password} onChange={(e) => changeState('password', e.target.value)} />
                    <button className="show" onClick={(e) => handleTogglePassword(e, 1)}>
                        <i className={state.showPass ? "bi bi-eye-slash" : "bi bi-eye"}></i>
                    </button>
                </div>
                <span>Confirm your password</span>
                <div className="password">
                    <input type={state.showPass2 ? 'text' : 'password'} placeholder="confirm your password" value={state.passwordConf} onChange={(e) => changeState('passwordConf', e.target.value)} />
                    <button className="show" onClick={(e) => handleTogglePassword(e, 2)}>
                        <i className={state.showPass2 ? "bi bi-eye-slash" : "bi bi-eye"}></i>
                    </button>
                </div>
                <label htmlFor="reference">Referred by:</label>
                <input type="text" name="referred" id="referred" readOnly value={state.referredBy} />
                <h5 className="tac">"By registering, you agree to all terms and conditions."</h5>
                <input className='botons' type="submit" value="Register" onClick={(e) => registrar(e)} />
            </div>
            <div className="msj-seccion">
                <img className="img1" alt="img1" />
                <img className="img2" alt="img2" />
                <span>Sign up now and start investing in your future!</span>

            </div>
        </div>
    )
}

export default Register;

