import "./CardUser.css"
import React, { useState, useEffect } from 'react';
import img0 from "../../Assets/Images/Logos/Logo_1pq.png"
import img1 from "../../Assets/Images/Logos/rangos/rank-1.png"
import img2 from "../../Assets/Images/Logos/rangos/Rank-2.png"
import img3 from "../../Assets/Images/Logos/rangos/Rank-3.png"
import img4 from "../../Assets/Images/Logos/rangos/Rank-4.png"
import img5 from "../../Assets/Images/Logos/rangos/Rank-5.png"
import img6 from "../../Assets/Images/Logos/rangos/Rank-6.png"
import img7 from "../../Assets/Images/Logos/rangos/Rank-7.png"
import img8 from "../../Assets/Images/Logos/rangos/Rank-8.png"
import img9 from "../../Assets/Images/Logos/rangos/Rank-9.png"
import appFirebase from "../../firebase-config";
import { getDatabase, ref, get,query,orderByChild,equalTo } from "firebase/database";
import { useUser } from "../../context/UserContext";

const CardUser = () => {
    const { userData, loading } = useUser()
    const [state, setState] = useState({
        rango: '',
        referidos: 0,
        referidosTotales: 0,
        capitalTotal: 0,
        imgRango: ''
    })

    const changeState=(name,value)=>{
        setState(prev=>({
            ...prev,
            [name]:value
        }))
    }
    useEffect(()=>{
        if(userData)
        generarData()
    },[userData && userData])

    const generarData = async () => {
        if(!userData) return
        
        const {rango,img}=calcularRango(userData.rank)
        const refDirect=(await getReferidosDirectos(userData)).length
        setState(prev=>({
            ...prev,
            rango: rango,
            imgRango: img,
            referidos:refDirect
        }))
        const referidosDirect=await getAllUsers()
        getRedTotal(1,userData.userName,referidosDirect)

    }

    const getReferidosDirectos = async (user) => {
        const db = getDatabase();
        const usersRef = ref(db, 'users/');
        const referidosDirectosQuery = query(usersRef, orderByChild('referredBy'), equalTo(user.userName));
        try {
            const snapshot = await get(referidosDirectosQuery);
            return snapshot.exists() ? Object.values(snapshot.val()) : [];
        } catch (error) {
            console.error("Error al obtener referidos directos:", error);
            return [];
        }
    };

    const getAllUsers = async () => {
        const db = getDatabase();
        const usersRef = ref(db, "users/");
        try {
            const snapshot = await get(usersRef);
            return snapshot.exists() ? Object.values(snapshot.val()) : []; // Devuelve un array vacío si no hay datos
        } catch (error) {
            console.error("Error al obtener los usuarios:", error);
            return []; // Siempre devuelve un array en caso de error
        }
    };
    
    const getRedTotal = (nivel, userName, users) => {
        if (nivel > 7 || !Array.isArray(users)) {
            return 0;
        }
    
        // Filtra los usuarios referidos directamente por el usuario actual
        const filteredUsers = users.filter(
            (user) => user.referredBy === userName
        );
    
        for (let referido of filteredUsers) {
            // Actualiza el estado acumulando los valores de los referidos
            setState((prev) => ({
                ...prev,
                capitalTotal: Number(prev.capitalTotal) + Number(referido.staterPack || 0),
                referidosTotales: Number(prev.referidosTotales+1),
            }));
    
            // Llamada recursiva para los referidos del siguiente nivel
            getRedTotal(nivel + 1, referido.userName, users);
        }
    };
    
    const calcularRango = (numero) => {
        switch (numero) {
            case 1:
                return{rango:"Zafiro Ejecutivo",img:img1}
            case 2:
                return{rango:"Ruby Ejecutivo",img:img2}
            case 3:
                return{rango:"Esmeralda Ejecutivo",img:img3}
            case 4:
                return{rango:'Ejecutivo Diamante',img:img4}
            case 5:
                return{rango:"Diamante Azul",img:img5}
            case 6:
                return{rango:"Diamante Negro",img:img6}
            case 7:
                return{rango:"Diamante Royal",img:img7}
            case 8:
                return{rango:"Diamante Corona",img:img8}
            case 9:
                return{rango:"Presidente Royal",img:img9}
            default:
                return{rango:"No Rank",img:img0}
        }
    }

    if (loading) return (<div className="spinner"></div>)


    return (
        <section className="userDetail">
            <div className="sec1">
                <p>Your rank</p>
                <span>{state.rango}</span>
            </div>
            <div className="sec2">
                <img className='rangoImg' src={state.imgRango} alt="rango" />
            </div>
            <div className="sec3">
                <span>{userData?.userName || ''}</span>
                <p>Team capital: ${state.capitalTotal.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} <img alt="logo_usdt" /></p>
                <p>Joined on: {userData?.admissionDate || ''}</p>
            </div>
            <div className="sec4">
                <div className="datosRedes">
                    <p className="titulo">Direct</p>
                    <p className="datoRed">{state.referidos}</p>
                </div>
            </div>
            <div className="sec5">
                <div className="datosRedes">
                    <p className="titulo">Total network</p>
                    <p className="datoRed">{state.referidosTotales}</p>
                </div>
            </div>
        </section>
    )

}
export default CardUser