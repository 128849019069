import { useEffect, useState } from 'react'
import './style/Historial.css'
export const Historial = ({ historial, fetchHistory, children }) => {
    const [loading, setLoading] = useState(true)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (historial) {
            setLoading(false)
        }
    }, [historial])

    const handleClickOpen = () => {
        setIsOpen(!isOpen)
        fetchHistory()
    }

    return (
        <section className="tableContainer">
            <div className="titulo">
                <h2><i class="bi bi-clock-history"></i> History</h2>
                <svg onClick={handleClickOpen} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000">
                    <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                </svg>
            </div>
            {isOpen && (
                <table>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td>
                                    <div className='spinner'></div>
                                </td>
                            </tr>
                        ) : historial ? (
                            historial.map((item) => (
                                children(item)
                            ))
                        ) : <span>You have not yet made a transaccion</span>
                        }
                    </tbody>
                </table >
            )}
        </section>

    )
}

